"use strict";

var Constants = require("../../constants/constants");

var Collapse = function() {
  var self = this;

  self.clickHandler = function($button, $nav, cb, noSlide) {
    $button.on(
      "click",
      new Constants().THROTTLE(function(e) {
        var $this = $(this);
        var $liParent = $this.parent("li");
        var toggleActiveClass = function() {
          if ($liParent.length > 0) {
            $liParent.toggleClass("is-active");
          } else {
            $this.toggleClass("is-active");
          }
        };

        e.preventDefault();

        self.navVisibility($nav, cb, noSlide, toggleActiveClass);

        if ($this.is("a")) {
          var $innerButton = $this.siblings("button");
          new Constants().ARIA_EXPANDED_TOGGLE($innerButton);
        } else {
          new Constants().ARIA_EXPANDED_TOGGLE($this);
        }
      }, 300)
    );
  };

  self.navVisibility = function($nav, cb, noSlide, toggleActiveClass) {
    if ($nav.hasClass("is-visible") && !$nav.hasClass("velocity-animating")) {
      self.hideNav($nav, cb, noSlide, toggleActiveClass);
    } else if (!$nav.hasClass("velocity-animating")) {
      self.showNav($nav, cb, noSlide, toggleActiveClass);
    }
  };

  self.showNav = function($nav, cb, noSlide, toggleActiveClass) {
    var slideDur = noSlide === true ? "0" : "300ms";

    $nav
      .velocity("slideDown", {
        easing: "easeOutQuint",
        duration: slideDur
      })
      .velocity(
        {
          opacity: "1"
        },
        {
          duration: "150ms",
          complete: function() {
            $nav.addClass("is-visible");
            toggleActiveClass();

            if (typeof cb === "function") {
              cb();
            }
          }
        }
      );
  };

  self.hideNav = function($nav, cb, noSlide, toggleActiveClass) {
    var slideDur = noSlide === true ? "0" : "300ms";

    $nav
      .velocity(
        {
          opacity: "0"
        },
        {
          duration: "100ms"
        }
      )
      .velocity("slideUp", {
        easing: "easeOutQuint",
        duration: slideDur,
        complete: function() {
          $nav.removeClass("is-visible");
          toggleActiveClass();

          if (typeof cb === "function") {
            cb();
          }
        }
      });
  };

  self.reset = function($button, $nav) {
    var $liParent = $button.parent("li");

    $button.off("click");

    $button.removeClass("is-active");

    if ($liParent.length > 0) {
      $liParent.removeClass("is-active");
    }

    new Constants().ARIA_EXPANDED_TOGGLE($button, false);
    $nav.removeClass("is-visible");
    $nav.removeAttr("style");
  };

  /**
   * @param {jQuery} $button
   * @param {jQuery} $nav
   * @param {Function} cb
   * @param {Boolean} noSlide
   * @param {Boolean} openByDefault
   */
  self.init = function($button, $nav, cb, noSlide, openByDefault) {
    self.clickHandler($button, $nav, cb, noSlide);

    if (openByDefault) {
      var setActiveClass = function() {
        var $liParent = $button.parent("li");
        if ($liParent.length > 0) {
          $liParent.addClass("is-active");
        } else {
          $button.addClass("is-active");
        }
      };
      self.showNav($nav, null, false, setActiveClass);
    }
  };
};

module.exports = Collapse;
