'use strict';

var LinkIcons = function(config) {


  var self = this;
  const nameByClass = [
    { class: 'external-link', name: 'Enllaç Extern' },
    { class: 'internal-link', name: 'Enllaç Intern' },
    { class: 'doc-pdf', name: 'Document PDF' },
    { class: 'doc-word', name: 'Document Word' },
    { class: 'excel', name: 'Document Excel' },
    { class: 'zip', name: 'Arxiu ZIP' },
  ];


  self.parseLinks = function() {
    const links = document.querySelectorAll('[data-icon]');

    links.forEach(link => {

      // Create an array out of the icons list
      let iconsList = link.getAttribute('data-icon').split(' ');

      iconsList.forEach(icon => {

        // Create the element and add its attributes
        let spanElement = document.createElement('span');
        spanElement.classList.add('icon');
        spanElement.classList.add('icon--' + icon);

        // Find the object with the same icon name.
        // If there aren't any matches returns 'undefined'
        let title = nameByClass.find( name => {
          return name.class === icon;
        });

        // Add attributes depending on the list of aria-labels
        if ( !(typeof title === 'undefined') ) {
          spanElement.setAttribute('role', 'img');
          spanElement.setAttribute('title', title.name);
        } else {
          spanElement.setAttribute('aria-hidden', 'true');
        }

        link.appendChild(spanElement);
      })
      // console.log(iconsList);
    })
  }


  self.init = function() {
    self.parseLinks();
  }
};

module.exports = LinkIcons;
