"use strict";


var CookiesManagerModal = function() {


  // Modal and window and buttons behaviours
  // =================================================

  var self = this;

  var $manager = $(".cookies-manager");
  var $managerWindow = $(".cookies-manager__window");
  var $managerOverlay = $(".cookies-manager__overlay");


  // Show Modal
  self.showModal = function() {
    if (
      $managerWindow.hasClass("velocity-animating") ||
      $managerOverlay.hasClass("velocity-animating")
    ) {
      return;
    }

    $manager.css("display", "block");

    $managerOverlay.velocity(
      {
        opacity: "1"
      },
      {
        duration: "300ms",
        complete: function() {
          $manager.trigger("modal-is-open");
          $("html").addClass("modal-is-open");

          $managerWindow.velocity(
            {
              opacity: "1",
              translateY: ["0%", "-30%"]
            },
            {
              easing: [400, 45]
            }
          );
        }
      }
    );
  };


  // Hide Modal
  self.hideModal = function() {
    if (
      $managerWindow.hasClass("velocity-animating") ||
      $managerOverlay.hasClass("velocity-animating")
    ) {
      return;
    }

    $managerWindow.velocity(
      {
        opacity: "0",
        translateY: ["-30%", "0%"]
      },
      {
        duration: "200ms",
        complete: function() {
          $managerOverlay.velocity(
            {
              opacity: "0"
            },
            {
              duration: "150ms",
              complete: function() {
                $manager.css("display", "none");
                $manager.trigger("modal-is-closed");
                $("html").removeClass("modal-is-open");
              }
            }
          );
        }
      }
    );

  };


  self.init = function() {
    //console.log('CookiesManagerModal loaded');
    //self.eventHandlers();
  };


};

module.exports = CookiesManagerModal;
