'use strict';

// Constructor
var Constants = function() {

  this.MEDIA_QUERIES = {
    'TINY'     :  '(min-width:     30rem)',
    'SMALLER'  :  '(min-width:     40rem)',
    'SMALL'    :  '(min-width:     48rem)',
    'TABLET'   :  '(min-width:  56.25rem)',
    'MEDIUM'   :  '(min-width:  68.75rem)',
    'LARGE'    :  '(min-width:     75rem)',
    'LARGER'   :  '(min-width:  81.25rem)',
    'XXLARGE'  :  '(min-width:    100rem)'
  }

  this.RANDOM_ID = function() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for( var i=0; i < 5; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  this.ARIA_EXPANDED_TOGGLE = function($button, setAttr) {

    if ($button[0].hasAttribute('aria-expanded')) {

      if (typeof setAttr === 'undefined') {

        var attr = $button.attr('aria-expanded');

        if (attr === 'false') {
          $button.attr('aria-expanded', true);
        } else {
          $button.attr('aria-expanded', false);
        }

      } else {

        $button.attr('aria-expanded', setAttr);
      }
    }
  }

  // Adapted from underscore:
  // https://github.com/jashkenas/underscore/blob/master/underscore.js#L810
  this.THROTTLE = function(func, wait, options) {
    var timeout, context, args, result;
    var previous = 0;
    var now = function() {
      return new Date().getTime();
    };
    if (!options) options = {};

    var later = function() {
      previous = options.leading === false ? 0 : now();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    };

    var throttled = function() {
      if (!previous && options.leading === false) previous = now();
      var remaining = wait - (now() - previous);
      context = this;
      args = arguments;
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        previous = now();
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };

    throttled.cancel = function() {
      clearTimeout(timeout);
      previous = 0;
      timeout = context = args = null;
    };

    return throttled;
  }

  this.DEBOUNCE = function(func, wait, immediate) {
    var timeout, result;

    var restArgs = function(func, startIndex) {
      startIndex = startIndex == null ? func.length - 1 : +startIndex;
      return function() {
        var length = Math.max(arguments.length - startIndex, 0),
            rest = Array(length),
            index = 0;
        for (; index < length; index++) {
          rest[index] = arguments[index + startIndex];
        }
        switch (startIndex) {
          case 0: return func.call(this, rest);
          case 1: return func.call(this, arguments[0], rest);
          case 2: return func.call(this, arguments[0], arguments[1], rest);
        }
        var args = Array(startIndex + 1);
        for (index = 0; index < startIndex; index++) {
          args[index] = arguments[index];
        }
        args[startIndex] = rest;
        return func.apply(this, args);
      };
    };

    var delay = restArgs(function(func, wait, args) {
      return setTimeout(function() {
        return func.apply(null, args);
      }, wait);
    });

    var later = function(context, args) {
      timeout = null;
      if (args) result = func.apply(context, args);
    };

    var debounced = restArgs(function(args) {
      if (timeout) clearTimeout(timeout);
      if (immediate) {
        var callNow = !timeout;
        timeout = setTimeout(later, wait);
        if (callNow) result = func.apply(this, args);
      } else {
        timeout = delay(later, wait, this, args);
      }

      return result;
    });

    debounced.cancel = function() {
      clearTimeout(timeout);
      timeout = null;
    };

    return debounced;
  };

  // https://gist.github.com/kflorence/3086552
  this.SCROLLBAR_WIDTH = function() {
    var inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";

    var outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);

    document.body.appendChild(outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;

    if (w1 == w2) {
      w2 = outer.clientWidth;
    }

    document.body.removeChild(outer);

    return (w1 - w2);
  }

  this.MATCHMEDIA_HANDLER = function(mqName, matchFun, unmatchFun) {
    var mq = new Constants().MEDIA_QUERIES[mqName];
    var mql = window.matchMedia(mq);
    var mediaQueryHandler = function(mql) {

      if (mql.matches) {
        if (typeof matchFun === 'function') {
          matchFun();
        }
      } else {
        if (typeof unmatchFun === 'function') {
          unmatchFun();
        }
      }
    };

    mql.addListener(mediaQueryHandler);

    mediaQueryHandler(mql);
  }
};

module.exports = Constants;
