"use strict";

var Highlight = function(config) {
  var self = this;
  var $subnav = config.subnav;
  var $container = config.highlightContainer;
  var $data = config.highlightData;
  var template = config.highlightTemplate;

  self.parseSubnavs = function() {
    $subnav.each(function() {
      var $this = $(this);
      var $myContainer = $this.find($container);
      var $myData = $this.find($data);

      if ($myData.length > 0) {
        var data = JSON.parse($myData.text());

        self.appendHighlight($myContainer, data);
      }
    });
  };

  self.appendHighlight = function($myContainer, data) {
    if ($myContainer.children().length === 0) {
      var $highlight = template(data);
      $myContainer.append($highlight);
    }
  };

  self.init = function() {
    self.parseSubnavs();
  };
};

module.exports = Highlight;
