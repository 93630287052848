'use strict';

var Constants = require('../constants/constants');

// Constructor
var SidebarCollapse = function(config) {

  var self = this;
  var $button = config.button;
  var $content = config.content;
  var eventName = config.eventName;

  self.setButtonAttrs = function() {
    $button.attr('role', 'button');
    $button.attr('aria-pressed', 'false');
  }

  self.clickHandler = function() {
    $button.on('click.' + eventName, new Constants().THROTTLE(function() {
      var $this = $(this);
      self.contentVisibility($this);
    }, 300));
  }

  self.contentVisibility = function(_$button) {
    if ($content.hasClass('is-visible') && !$content.hasClass('velocity-animating')) {
      self.hideContent();
      _$button.removeClass('is-active');
    } else if (!$content.hasClass('velocity-animating')) {
      self.showContent();
      _$button.addClass('is-active');
    }
  }

  self.showContent = function() {
    $content.addClass('is-visible');
    $content.velocity(
      'slideDown',
      {
        duration: 400,
        easing: 'easeOutQuart'
      }
    );
    $content.velocity(
      {
        opacity: 1
      },
      {
        duration: 200
      }
    );
  }

  self.hideContent = function() {
    $content.removeClass('is-visible');
    $content.velocity(
      {
        opacity: 0
      },
      {
        duration: 150
      }
    );
    $content.velocity(
      'slideUp',
      {
        duration: 400,
        easing: 'easeOutQuart'
      }
    );
  }

  self.reset = function() {
    $button.off('click.' + eventName);
    $button.removeAttr('role aria-pressed');
    $button.removeClass('is-active');
    $content.removeAttr('style');
    $content.removeClass('is-visible');
  }

  self.init = function() {

    var largeViewports = function() {
      self.reset();
    }

    var smallViewports = function() {
      self.setButtonAttrs();
      self.clickHandler();
    }

    new Constants().MATCHMEDIA_HANDLER('SMALL', largeViewports, smallViewports);
  }
};

module.exports = SidebarCollapse;
