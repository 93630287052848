'use strict';

require('image-map-resizer');

var ResponsiveMap = function (config) {
  var self = this;
  var $container = $(config.container);

  self.parseContainers = function () {
    $container.each(function () {
      var $this = $(this);
      var $map = $this.find('map');
      self.initResponsiveMap($map);
    });
  };

  self.initResponsiveMap = function ($map) {
    $map.imageMapResize();
  };

  self.init = function () {
    self.parseContainers();
  };
};

module.exports = ResponsiveMap;
