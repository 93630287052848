'use strict';

var SidebarCollapse = require('../sidebar-collapse/sidebar-collapse');

// Constructor
var SidebarLinked = function() {

  var self = this;

  self.config = {
    'button'     :  $('.sidebar-linked__button'),
    'content'    :  $('.sidebar-linked__content'),
    'eventName'  :  'sidebarLinked'
  }

  self.init = function() {
    new SidebarCollapse(self.config).init();
  }
};

module.exports = SidebarLinked;
