'use strict';


require('velocity-animate');
var CookiesManagerModal = require("./lib/modal");
var CookieAlertModal = require("./lib/alert");


// Constructor
var CookiesManager = function () {

  var self = this;


  // One key per Cookies group
  //
  // storage: Name of storage variable and class used
  // toggle: Checkbox used to manage group's preferences
  // class: Class name associated to scripts and iframes
  // =================================================

  var cookiesConfig = {

    preferences: {
      storage: 'udgCookiesPreferences',
      toggle: document.querySelector('#cookies-preferences'),
      class: 'cookies-preferences'
    },
    analytics: {
      storage: 'udgCookiesAnalytics',
      toggle: document.querySelector('#cookies-analytics'),
      class: 'cookies-analytics'
    },
    marketing: {
      storage: 'udgCookiesMarketing',
      toggle: document.querySelector('#cookies-marketing'),
      class: 'cookies-marketing'
    }

  };


  // Show localStorage values
  self.userPreferencesShow = function() {
    console.log('******');
    for (var [key, value] of Object.entries(cookiesConfig)) {
      console.log(key + ': ' + localStorage.getItem(value.storage));
    }
  }



  // Set checkboxes value based on the preferences
  // =================================================

  self.initPreferences = function() {

    Object.keys(cookiesConfig).forEach(function (key) {

      var cookie = cookiesConfig[key];

      // If the global cookie is missing, delete localStorage
      if ( !self.checkPreferencesCookie() ) {
        localStorage.removeItem(cookie.storage);
      }

      if ( localStorage.getItem(cookie.storage) === "Accept" ) {
        cookie.toggle.checked = true;
      } else {
        cookie.toggle.checked = false;
      }

    });

    //self.userPreferencesShow();
  }


  self.acceptAll = function() {
    //console.log('******');
    //console.log('All cookies accepted!');

    Object.keys(cookiesConfig).forEach(function (key) {
      var cookie = cookiesConfig[key];
      cookie.toggle.checked = true;
      localStorage.setItem(cookie.storage, 'Accept');
    });

    self.setPreferencesCookie();
  }



  // Checkboxes functionalities (Save on click)
  // =================================================

  /* self.togglesHandler = function() {

    Object.keys(cookiesConfig).forEach(function (key) {

      var cookie = cookiesConfig[key];

      cookie.toggle.addEventListener('change', function () {

        if ( localStorage.getItem(cookie.storage) === 'Accept' ) {

          localStorage.setItem(cookie.storage, 'Reject');
          this.checked = false;

        } else {

          localStorage.setItem(cookie.storage, 'Accept');
          this.checked = true;

          // Accept All cookies
          if (key === 'all') {
            self.acceptAll();
          }

        }

        //self.userPreferencesShow();
        self.scriptsHandler();
        self.iframesHandler();
        self.setPreferencesCookie();

      });
    });
  } */



  // Save Preferences
  // =================================================

  self.savePreferences = function() {

    Object.keys(cookiesConfig).forEach(function (key) {

      var cookie = cookiesConfig[key];

      if ( cookie.toggle.checked ) {
        localStorage.setItem(cookie.storage, 'Accept');
      } else {
        localStorage.setItem(cookie.storage, 'Reject');
      }

    });

    self.setPreferencesCookie();
  };



  // Scripts
  // =================================================

  self.scriptsHandler = function() {

    // Go through all the cookies' groups
    Object.keys(cookiesConfig).forEach(function (key) {

      var cookie = cookiesConfig[key];
      var scripts = document.querySelectorAll('script.' + cookie.class);
      var documentFragment, s, i, y, attrib;
      documentFragment = document.createDocumentFragment();


      for (i = 0; i < scripts.length; i++) {

        var setting = localStorage.getItem(cookie.storage);

        if (setting === "Accept") {

          // Source Scripts
          if (scripts[i].hasAttribute('data-src')) {

            s = document.createElement('script');
            s.src = scripts[i].getAttribute("data-src");
            documentFragment.appendChild(s);

            /* if (typeof postscribe !== "undefined") {
              postscribe(scripts[i].parentNode, '<script src="' + scripts[i].getAttribute("data-ce-src") + '"></script>');
            } */

          // Inline Scripts
          } else {

            s = document.createElement('script');
            s.type = 'text/javascript';

            for (y = 0; y < scripts[i].attributes.length; y++) {
              attrib = scripts[i].attributes[y];
              if (attrib.specified) {
                if ((attrib.name != 'type') && (attrib.name != 'class')) {
                  s.setAttribute(attrib.name, attrib.value);
                }
              }
            }

            s.innerHTML = scripts[i].innerHTML;
            documentFragment.appendChild(s);

          }
        }
      }

      document.body.appendChild(documentFragment);

    });
  }



  // iFrames
  // =================================================

  self.iframesHandler = function() {


    // Go through all the cookies' groups
    Object.keys(cookiesConfig).forEach(function (key) {

      var cookie = cookiesConfig[key];
      var i;
      var setting = localStorage.getItem(cookie.storage);
      var iframes = document.querySelectorAll('iframe.' + cookie.class);
      var placeholders = document.getElementsByClassName(cookie.class + '--placeholder');


      // Hide or show iframes
      for (i = 0; i < iframes.length; i++) {

        var iframe = iframes[i];

        if ( setting === "Accept" ) {
          iframe.src = iframe.getAttribute("data-src");
          // iframe.style.display = "block";
        } else {
          // iframe.style.display = "none";
        }
      }


      // Hide or show placeholders
      for (i = 0; i < placeholders.length; i++) {

        var placeholder = placeholders[i];

        if ( setting === "Accept" ) {
          placeholder.style.display = "none";
        } else {
          placeholder.style.display = "block";
        }
      }

    });
  }



  // Preferences Cookie
  // =================================================

  self.setPreferencesCookie = function () {

    // Adding a path and date to the cookie
    let expiryDate = new Date(Date.now());
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);
    expiryDate = expiryDate.toUTCString();
    document.cookie = "udgCookiesPreferences=set; path=/; expires=" + expiryDate;

    new CookieAlertModal().hideAlert();

    // Only once the cookie is set
    // the functionalities are reviewed
    self.scriptsHandler();
    self.iframesHandler();

  }

  self.checkPreferencesCookie = function () {

    if (document.cookie.split(';').some((item) => item.trim().startsWith('udgCookiesPreferences='))) {
      return true;
    } else {
      return false;
    }
  }



  // Modal, window and buttons behaviours
  // =================================================

  var $managerBtnOpen = $(".cookies-manager__btn-open");
  var $managerBtnSave = $(".cookies-manager__btn-save");
  var $managerBtnAcceptAll = $(".cookies-manager__btn-accept");
  var $managerModalClose = $(".cookies-manager__close");


  self.eventHandlers = function() {

    $managerBtnOpen.on("click", function(e) {
      e.preventDefault();

      self.initPreferences();
      new CookiesManagerModal().showModal();
    });

    $managerBtnAcceptAll.on("click", function(e) {
      e.preventDefault();

      new CookiesManagerModal().hideModal();
      self.acceptAll();
    });

    $managerBtnSave.on("click", function(e) {
      e.preventDefault();

      new CookiesManagerModal().hideModal();
      self.savePreferences();
    });

    $managerModalClose.on("click", function(e) {
      e.preventDefault();
      new CookiesManagerModal().hideModal();
    });

  }



  // Init functions
  // =================================================

  self.init = function () {

    self.initPreferences();
    self.scriptsHandler();
    self.iframesHandler();
    self.eventHandlers();

  }
};


module.exports = CookiesManager;
