'use strict'

var Constants = require('../constants/constants')

// Constructor
var Collapse = function () {
  var self = this

  self.config = {
    'button': $('[data-collapse]'),
    'containerEl': $('.collapse')
  }

  var $buttonEl = self.config.button,
    $containerEl = self.config.containerEl

  self.clickHandler = function () {
    $buttonEl.each(function () {
      var $this = $(this),
        $container = $($this.attr('data-collapse'))

      $this.on('click', new Constants().THROTTLE(function (e) {
        if ($this.attr('data-collapse-parent')) {
          self.accordion($this, $container)
        }

        if ($this.is('a, a *')) {
          e.preventDefault()
        }

        var activeState = function () {
          // $this.toggleClass('is-active');

          if ($this.attr('data-collapse-alt-text')) {
            self.altText($this)
          }
        }

        self.containerVisibility($container, $this, activeState)
      }, 300))
    })
  }

  self.containerVisibility = function ($container, $button, cb) {
    if ($container.hasClass('is-visible')) {
      self.hideContainer($container, $button, cb)
    } else {
      self.showContainer($container, $button, cb)
    }
  }

  self.accordion = function ($button, $container) {
    var $parent = $($button.attr('data-collapse-parent'))
    var $otherContainers = $parent.find($containerEl.not($container))
    var $otherButtons = $parent.find($buttonEl.not($button))

    self.hideContainer($otherContainers, $otherButtons)
  }

  self.altText = function ($button) {
    var oldText = $button.text(),
      newText = $button.attr('data-collapse-alt-text')

    $button.text(newText)
    $button.attr('data-collapse-alt-text', oldText)
  }

  self.showContainer = function ($container, $button, cb) {
    if (!$container.hasClass('is-visible') && !$container.hasClass('velocity-animating')) {
      var $stickySidebar = $('[data-sticky-sidebar]')

      if ($stickySidebar.length) {
        var $stickyParent = $('[data-sticky-sidebar]').parent()
        var fixHeight = $stickyParent.height()

        $stickyParent.css('height', fixHeight)
      }

      $button.addClass('is-active')
      $container.addClass('is-visible')
      $container
        .velocity(
          'slideDown',
        {
          duration: 400,
          easing: 'easeOutQuart'
        }
        )
        .velocity(
        {
          opacity: '1'
        },
        {
          duration: 300,
          complete: function () {
            $button.attr('aria-expanded', 'true')

            if (typeof cb === 'function') {
              cb()
            }

            if ($stickySidebar.length) {
              $stickyParent.css('height', 'auto')
              $(document.body).trigger('sticky_kit:recalc')
            }
          }
        }
        )
    }
  }

  self.hideContainer = function ($container, $button, cb) {
    if ($container.hasClass('is-visible') && !$container.hasClass('velocity-animating')) {
      $button.removeClass('is-active')
      $container.removeClass('is-visible')
      $container
        .velocity(
        {
          opacity: '0'
        },
        {
          duration: 200
        }
        )
        .velocity(
          'slideUp',
        {
          duration: 400,
          easing: 'easeOutQuart',
          complete: function () {
            $button.attr('aria-expanded', 'false')

            if (typeof cb === 'function') {
              cb()
            }

            $(document.body).trigger('sticky_kit:recalc')
          }
        }
        )
    }
  }

  self.init = function () {
    self.clickHandler()
  }
}

module.exports = Collapse
