"use strict";

var StickySidebar = require("../sticky-sidebar/sticky-sidebar");

// Constructor
var SideNav = function() {
  var self = this;

  self.config = {
    navItem: $(".side-nav__list li"),
    iconTemplate:
      '<span class="icon icon--triangle side-nav__list-toggle-icon"></span>'
  };

  var $navItem = self.config.navItem,
    iconTemplate = self.config.iconTemplate;

  self.parseNavItems = function() {
    $navItem.each(function() {
      var $this = $(this),
        $button = $this.children("a"),
        $subnav = $this.children("ul");

      if ($subnav.length > 0) {
        if (
          $(window).width() >= 768 &&
          $subnav.hasClass("is-open-by-default")
        ) {
          self.showSubnav($subnav);
        } else {
          $subnav.addClass("is-hidden");
        }
        self.addIcon($button);
        self.clickHandler($button, $subnav);
      }
    });
  };

  self.addIcon = function($button) {
    $button.append(iconTemplate);
  };

  self.clickHandler = function($button, $subnav) {
    $button.on("click", function(e) {
      e.preventDefault();

      var $this = $(this);

      $this.toggleClass("is-active");
      self.subnavVisibility($subnav);
    });
  };

  self.subnavVisibility = function($subnav) {
    if ($subnav.hasClass("is-hidden")) {
      self.showSubnav($subnav);
    } else {
      self.hideSubnav($subnav);
    }
  };

  self.showSubnav = function($subnav) {
    $subnav
      .velocity("slideDown", {
        easing: "easeOutQuint",
        duration: "200ms"
      })
      .velocity(
        {
          opacity: "1"
        },
        {
          duration: "100ms",
          complete: function() {
            $subnav.removeClass("is-hidden");

            new StickySidebar().forceRecalc();
          }
        }
      );
  };

  self.hideSubnav = function($subnav) {
    $subnav
      .velocity(
        {
          opacity: "0"
        },
        {
          duration: "100ms"
        }
      )
      .velocity("slideUp", {
        easing: "easeOutQuint",
        duration: "200ms",
        complete: function() {
          $subnav.addClass("is-hidden");

          new StickySidebar().forceRecalc();
        }
      });
  };

  self.init = function() {
    self.parseNavItems();
  };
};

module.exports = SideNav;
