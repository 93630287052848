// Main javascript entry point
// Should handle bootstrapping/starting application
require('../_modules/modernizr/modernizr');
require('../_modules/promise-polyfill/promise-polyfill');
require('velocity-animate');
require('sticky-kit/dist/sticky-kit');

// IMPORTANT PER A QUE FUNCIONI BÉ AMB BROWSERIFY:
// https://github.com/kenwheeler/slick/issues/1010#issuecomment-124861077
// require('slick-carousel');

// import FontfaceHandler from '../_modules/fontface-handler/fontface-handler';
import MainHeader from '../_modules/main-header/main-header';
import StickySidebar from '../_modules/sticky-sidebar/sticky-sidebar';
import SideNav from '../_modules/side-nav/side-nav';
import Modal from '../_modules/modal/modal';
import Collapse from '../_modules/collapse/collapse';
import Plyr from '../_modules/plyr/plyr';
// import Carousel from '../_modules/carousel/carousel';
import Tabs from '../_modules/tabs/tabs';
import InlineNav from '../_modules/inline-nav/inline-nav';
import ContactCard from '../_modules/contact-card/contact-card';
import PubSearch from '../_modules/pub-search/pub-search';
import Filters from '../_modules/filters/filters';
import SidebarLinked from '../_modules/sidebar-linked/sidebar-linked';
import CookieAlert from '../_modules/cookie-alert/cookie-alert';
import CookiesManager from '../_modules/cookies-manager/cookies-manager';
import ImageMap from '../_modules/image-map/image-map';
import SidebarAdvancedSearch from '../_modules/sidebar-advanced-search/sidebar-advanced-search';
import ShareModule from '../_modules/share-module/share-module';
import LinkIcons from '../_modules/link-icons/link-icons';
// import Datatable from '../_modules/datatable/datatable';

$(() => {
  // new FontfaceHandler().init();
  new MainHeader().init();
  new StickySidebar().init();
  new SideNav().init();
  new Modal().init();
  new Collapse().init();
  new Plyr().init();
  // new Carousel().init();
  new Tabs().init();
  new InlineNav().init();
  new ContactCard().init();
  new PubSearch().init();
  new Filters().init();
  new SidebarLinked().init();
  new CookieAlert().init();
  new CookiesManager().init();
  new ImageMap().init();
  new SidebarAdvancedSearch().init();
  new ShareModule().init();
  new LinkIcons().init();
  // new Datatable().init();
});
