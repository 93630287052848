"use strict";

// Constructor
var Modal = function() {
  var self = this;

  self.config = {
    button: $("[data-show-modal]"),
    modalWindow: ".modal__window",
    modalCloseButton: ".modal__window__close",
    modalOverlay: ".modal__overlay"
  };

  var $button = self.config.button,
    modalWindow = self.config.modalWindow,
    modalCloseButton = self.config.modalCloseButton,
    modalOverlay = self.config.modalOverlay;

  self.openConfig = function(
    $button,
    modalWindow,
    modalCloseButton,
    modalOverlay
  ) {
    $button.each(function() {
      var $this = $(this),
        $modal = $("#" + $this.data("show-modal")),
        $modalWindow = $modal.find(modalWindow),
        $modalCloseButton = $modal.find(modalCloseButton),
        $modalOverlay = $modal.find(modalOverlay);

      self.openModal($this, $modal, $modalWindow, $modalOverlay);
      self.closeModal($modalCloseButton, $modal, $modalWindow, $modalOverlay);
      self.closeModal($modalOverlay, $modal, $modalWindow, $modalOverlay);
    });
  };

  self.openModal = function($button, $modal, $modalWindow, $modalOverlay) {
    $button.on("click", function(e) {
      e.preventDefault();

      self.showModal($modal, $modalWindow, $modalOverlay);
    });
  };

  self.closeModal = function($button, $modal, $modalWindow, $modalOverlay) {
    $button.on("click", function(e) {
      e.preventDefault();

      self.hideModal($modal, $modalWindow, $modalOverlay);
    });
  };

  self.showModal = function($modal, $modalWindow, $modalOverlay) {
    if (
      $modalWindow.hasClass("velocity-animating") ||
      $modalOverlay.hasClass("velocity-animating")
    ) {
      return;
    }

    $modal.css("display", "block");

    $modalOverlay.velocity(
      {
        opacity: "1"
      },
      {
        duration: "300ms",
        complete: function() {
          $modal.trigger("modal-is-open");
          $("html").addClass("modal-is-open");

          $modalWindow.velocity(
            {
              opacity: "1",
              translateY: ["0%", "-30%"]
            },
            {
              easing: [400, 45]
            }
          );
        }
      }
    );
  };

  self.hideModal = function($modal, $modalWindow, $modalOverlay) {
    if (
      $modalWindow.hasClass("velocity-animating") ||
      $modalOverlay.hasClass("velocity-animating")
    ) {
      return;
    }

    $modalWindow.velocity(
      {
        opacity: "0",
        translateY: ["-30%", "0%"]
      },
      {
        duration: "200ms",
        complete: function() {
          $modalOverlay.velocity(
            {
              opacity: "0"
            },
            {
              duration: "150ms",
              complete: function() {
                $modal.css("display", "none");
                $modal.trigger("modal-is-closed");
                $("html").removeClass("modal-is-open");
              }
            }
          );
        }
      }
    );
  };

  self.init = function() {
    self.openConfig($button, modalWindow, modalCloseButton, modalOverlay);
  };
};

module.exports = Modal;
