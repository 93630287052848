"use strict";


require('velocity-animate');
var CookiesManager = require("../cookies-manager/cookies-manager");


// Constructor
var CookieAlert = function () {

  var self = this;

  self.config = {
    'alert': $('.cookie-alert'),
    'close': $('#cookieAlertClose')
  }

  var $alert = self.config.alert;
  var $close = self.config.close;


  // Check if cookies' preferences were set
  self.preferencesHandler = function () {

    var preferencesAreSet = new CookiesManager().checkPreferencesCookie();

    if (preferencesAreSet === false) {
      self.showAlert();
      self.clickHandler();
    }
  }


  // Set events to click
  // Showing the cookies manager is handled by CSS class .cookies-manager__open
  self.clickHandler = function () {

    $close.on('click', function (e) {
      e.preventDefault();
      self.hideAlert();
      new CookiesManager().setPreferencesCookie();
      new CookiesManager().acceptAll();
    })
  }


  // Show cookies alert
  self.showAlert = function () {

    $alert.velocity({
      translateY: ['0%', '100%']
    }, {
      display: 'block'
    });
  }


  // Hide cookies alert
  self.hideAlert = function () {

    $alert.velocity({
      translateY: '100%'
    }, {
      display: 'none'
    });
  }


  self.init = function () {
    self.preferencesHandler();
  }

};


module.exports = CookieAlert;
