'use strict';

var SidebarCollapse = require('../sidebar-collapse/sidebar-collapse');

// Constructor
var ContactCard = function() {

  var self = this;

  self.config = {
    'button'     :  $('.contact-card__button'),
    'content'    :  $('.contact-card__content'),
    'eventName'  :  'contactCard'
  }

  self.init = function() {
    new SidebarCollapse(self.config).init();
  }
};

module.exports = ContactCard;
