'use strict';

var plyr = require('plyr');
var CookiesManager = require('../cookies-manager/cookies-manager');


// Constructor
var Plyr = function() {

  var self = this;

  self.config = {
    videoEl: '.plyr-wrapper > div'
  }


  self.loadVideos = function () {

    if ($(self.config.videoEl).length) {

      var players = plyr.setup(
        self.config.videoEl, {
          loadSprite: false,
          youtube: {
            noCookie: true,
            disablekb: '1',
          },
          controls: [
            'play-large',
            'play',
            'progress',
            'current-time',
            'mute',
            'volume',
            'fullscreen',
          ],
        }
      );

      players.forEach(function(player) {

        var hero = document.querySelector('.-is-hero');

        var videoContainer;
        var btnPlay;
        var btnProgress;
        var btnMute;
        var btnVolume;
        var btnFullscreen;
        var iframe;

        player.on('ready', function(e) {

          // Add a11y attributes to elements added
          // dynamically because they're created by Plyr
          // and Slick Carousel don't disable them

          if (hero) {
            videoContainer = hero.querySelector('.plyr--video');
            btnPlay = hero.querySelector('.plyr__controls button[data-plyr="play"]');
            btnProgress = hero.querySelector('.plyr__controls input[data-plyr="seek"]');
            btnMute = hero.querySelector('.plyr__controls button[data-plyr="mute"]');
            btnVolume = hero.querySelector('.plyr__controls input[data-plyr="volume"]');
            btnFullscreen = hero.querySelector('.plyr__controls button[data-plyr="fullscreen"]');
            iframe = hero.querySelector('iframe');

            videoContainer.removeAttribute('tabindex');
            btnPlay.setAttribute('tabindex', '-1');
            btnProgress.setAttribute('tabindex', '-1');
            btnMute.setAttribute('tabindex', '-1');
            btnVolume.setAttribute('tabindex', '-1');
            btnFullscreen.setAttribute('tabindex', '-1');
            iframe.setAttribute('tabindex', '-1');
            iframe.setAttribute('role', 'presentation');
          }
        });

        player.on('play', function(e) {
          var parent = e.currentTarget.parentNode;
          parent.classList.remove('is-stopped');
          parent.classList.add('is-playing');

          if (hero) {
            btnPlay.removeAttribute('tabindex');
            btnProgress.removeAttribute('tabindex');
            btnMute.removeAttribute('tabindex');
            btnVolume.removeAttribute('tabindex');
            btnFullscreen.removeAttribute('tabindex');
          }
        });

        player.on('ended', function(e) {
          var parent = e.currentTarget.parentNode;
          parent.classList.remove('is-playing');
          parent.classList.add('is-stopped');
        });

      });
    }

  }


  // Check both if Cookies are set and localStorage for Marketing is working
  self.checkCookies = function () {

    var preferencesAreSet = new CookiesManager().checkPreferencesCookie();

    if (preferencesAreSet) {
      if ( localStorage.getItem('udgCookiesMarketing') === 'Accept' ) {
        self.loadVideos();
      }
    }
  }


  // Check on Cookies' related actions
  self.listenCookies = function () {

    var $managerBtnSave = $('.cookies-manager__btn-save');
    var $managerBtnAcceptAll = $('.cookies-manager__btn-accept');

    // Check directly against the toggle when
    // the user interacts with buttons due to
    // checking against the browser delay
    var marketingCookieToggle = document.querySelector('#cookies-marketing');

    $managerBtnAcceptAll.on('click', function(e) {
      // On Accept all, just load videos.
      self.loadVideos();
    });

    $managerBtnSave.on('click', function(e) {
      if (marketingCookieToggle.checked === true) {
        self.loadVideos();
      }
    });

  }


  self.init = function() {
    self.checkCookies();
    self.listenCookies();
  }


};

module.exports = Plyr;
