'use strict';

var Constants = require('../../constants/constants');
var hoverintent = require('hoverintent');
// const FocusSubnav = require('./focus-subnav');

var DesktopInnerSubnavs = function(config) {
  var self = this;
  var $item = config.subnavItem;
  var $link = config.subnavLink;
  var subnavIconClass = config.subnavIconClass;
  var subnavIconTemplate = config.subnavIconTemplate;
  var $subnav = config.innerSubnavContainer;
  var backButtonClass = config.backInnerSubnavBtnClass;
  var backButtonTemplate = config.backInnerSubnavBtnTemplate;
  var $listOuter = config.subnavList;
  var $outer = config.subnav;

  self.parseItems = function() {
    $item.each(function() {
      var $this = $(this);
      var $myLink = $this.find($link);
      var $mySubnav = $this.find($subnav);
      var $myListOuter = $this.parents($listOuter);
      var $myOuter = $this.parents($outer);
      var hasSubnav;

      if ($mySubnav.length > 0) {
        hasSubnav = true;

        self.appendIcon($myLink);
        self.appendBackButton($myLink, $mySubnav);
        self.focusHandler($myLink, $mySubnav, $myOuter);

      } else {
        hasSubnav = false;
      }

      if (!Modernizr.touchevents) {
        // self.hoverHandler(
        //   $myLink,
        //   $mySubnav,
        //   $myListOuter,
        //   $myOuter,
        //   hasSubnav
        // );
      }
    });
  };

  self.appendIcon = function($myLink) {
    var $icons = $myLink.find($('.' + subnavIconClass));

    if ($icons.length <= 0) {
      var $icon = $(subnavIconTemplate);

      $myLink.append($icon);
    }
  };

  self.appendBackButton = function($myLink, $mySubnav) {
    var $closeButtons = $mySubnav.find($('.' + backButtonClass));

    if ($closeButtons.length <= 0) {
      var $closeButton = $(backButtonTemplate);

      $mySubnav.append($closeButton);

      self.backClickHandler($myLink, $mySubnav, $closeButton);
    }
  };

  self.backClickHandler = function($myLink, $mySubnav, $closeButton) {
    $closeButton.on('click', function() {
      self.hideSubnav($mySubnav);
      $myLink.removeClass('is-active');
      $myLink.focus();

      $link.removeClass('is-dim'); // Reset opacity effect for subnav links
    });
  };

  self.hoverHandler = function(
    $myLink,
    $mySubnav,
    $myListOuter,
    $myOuter,
    hasSubnav
  ) {
    var myLink = $myLink[0];
    var mq = new Constants().MEDIA_QUERIES.MEDIUM;
    var mql = window.matchMedia(mq);

    var hideSubnavs = function() {
      self.hideCurrentSubnavs(null, null);
      $link.removeClass('is-active is-dim');
    };

    var hoverEvents = hoverintent(
      myLink,
      function() {
        if (hasSubnav === true) {
          self.subnavVisibility($myLink, $mySubnav, $myOuter);
        } else {
          hideSubnavs();
        }
      },
      function() {}
    );

    // Hover out of container
    var hoverOutOuter = hoverintent(
      $myListOuter[0],
      function() {},
      function() {
        hideSubnavs();
      }
    );

    // Remove hoverintent listeners
    var mediaQueryHandler = function(mql) {
      if (!mql.matches) {
        hoverEvents.remove();
        hoverOutOuter.remove();
      }
    };

    mql.addListener(mediaQueryHandler);
    mediaQueryHandler(mql);
  };

  self.focusHandler = function($myLink, $mySubnav, $myOuter) {

    // On keydown Enter, activate the subnav
    $myLink.on('keydown', function(event) {
      if (event.key == 'Enter') {
        if ($mySubnav.hasClass('is-visible')) {
          self.hideSubnav($mySubnav);
        } else {
          self.subnavVisibility($myLink, $mySubnav, $myOuter);
        }
      }
    });

    // On Click, activate the subnav
    $myLink.on('click', function(event) {
      event.preventDefault();

      if ($mySubnav.hasClass('is-visible')) {
        self.hideSubnav($mySubnav);
      } else {
        self.subnavVisibility($myLink, $mySubnav, $myOuter);
      }
    });
  };

  self.subnavVisibility = function($myLink, $mySubnav, $myOuter) {
    var showMySubnav = function() {
      self.showSubnav($mySubnav, $myOuter);
    };

    if ($subnav.hasClass('is-visible')) {
      self.hideCurrentSubnavs($mySubnav, showMySubnav);
    } else {
      showMySubnav();
    }

    $link.removeClass('is-active');
    $link.addClass('is-dim');
    $myLink.removeClass('is-dim');
    $myLink.addClass('is-active');
  };

  self.hideCurrentSubnavs = function($currentSubnav, cb) {
    $subnav.not($currentSubnav).each(function() {
      var $this = $(this);

      if ($this.hasClass('is-visible')) {
        self.hideSubnav($this, cb);
      }
    });
  };

  self.showSubnav = function($mySubnav) {
    if (!$mySubnav.hasClass('velocity-animating')) {
      $mySubnav.addClass('is-visible');
      $mySubnav.velocity(
        {
          opacity: ['0', '1']
        },
        {
          display: 'block',
          duration: 0,
          complete: function() {
            $mySubnav.velocity(
              {
                opacity: '1'
              },
              {
                duration: '300ms'
              }
            );
          }
        }
      );
    }
  };

  self.hideSubnav = function($mySubnav, cb) {
    if (!$mySubnav.hasClass('velocity-animating')) {
      $mySubnav.removeClass('is-visible');
      $mySubnav.velocity('fadeOut', {
        duration: '100ms',
        complete: function() {
          if (typeof cb === 'function') {
            cb();
          }
        }
      });
    }

    $link.removeClass('is-dim'); // Reset opacity effect for subnav links
  };

  self.reset = function() {
    $link.off('focus');
    $link.removeClass('is-active is-dim');
    $subnav.removeAttr('style');
    $subnav.removeClass('is-visible');
    $subnav.removeClass('has-large-height');
  };

  self.init = function() {
    self.parseItems();
  };
};

module.exports = DesktopInnerSubnavs;
