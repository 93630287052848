"use strict";


require('velocity-animate');


// Constructor
var CookieAlertModal = function () {

  var self = this;

  var $alert = $('.cookie-alert');

  // Hide cookies alert
  self.hideAlert = function () {

    $alert.velocity({
      translateY: '100%'
    }, {
      display: 'none'
    });
  }

};


module.exports = CookieAlertModal;
