export const i18n = {
  'ca-ES': {
    'firstDay': 1,
    'i18n': {
      'previousMonth': 'Mes anterior',
      'nextMonth': 'Mes següent',
      'months': [
        'gener',
        'febrer',
        'març',
        'abril',
        'maig',
        'juny',
        'juliol',
        'agost',
        'setembre',
        'octubre',
        'novembre',
        'desembre'
      ],
      'weekdays': [
        'diumenge',
        'dilluns',
        'dimarts',
        'dimecres',
        'dijous',
        'divendres',
        'dissabte'
      ],
      'weekdaysShort': ['dg', 'dl', 'dm', 'dc', 'dj', 'dv', 'ds']
    }
  },
  'es-es': {
    'firstDay': 1,
    'i18n': {
      'previousMonth': 'Mes anterior',
      'nextMonth': 'Mes siguiente',
      'months': [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre'
      ],
      'weekdays': [
        'domingo',
        'lunes',
        'martes',
        'miércoles',
        'jueves',
        'viernes',
        'sábado'
      ],
      'weekdaysShort': ['do', 'lu', 'ma', 'mi', 'ju', 'vi', 'sa']
    }
  },
  'en': {
    'firstDay': 0,
    'i18n': {
      'previousMonth': 'Previous month',
      'nextMonth': 'Next month',
      'months': [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      'weekdays': [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
      'weekdaysShort': ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
    }
  }
}
