'use strict';

var Constants = require('../constants/constants');

// Constructor
var StickySidebar = function() {

  var self = this;
  var $window = $(window);

  self.config = {

    'sidebar'        :  $('[data-sticky-sidebar]'),
    'defaultOffset'  :  110
  }

  var $sidebar = self.config.sidebar;
  var defaultOffset = self.config.defaultOffset;

  self.StickyOptions = function() {
    this.offset_top = defaultOffset;
  }

  self.stickSidebar = function() {

    $sidebar.trigger('sticky_kit:detach');
    $sidebar.each(function() {

      var $this = $(this);
      var options = new self.StickyOptions();

      if ($this.attr('data-sticky-parent')) {
        var parent = $this.attr('data-sticky-parent');
        options.parent = parent;
      }

      if ($this.attr('data-sticky-offset')) {
        var offset = parseInt($this.attr('data-sticky-offset'));
        options.offset_top = offset;
      }

      $this.stick_in_parent(options);
    });
  }

  self.unstickSidebar = function() {
    $sidebar.trigger('sticky_kit:detach');
  }

  self.forceRecalc = function() {
    $(document.body).trigger('sticky_kit:recalc');
  }

  self.init = function() {

    var resizeHandler = new Constants().DEBOUNCE(function() {
      self.unstickSidebar();
      self.stickSidebar();
    });

    var largeViewports = function() {
      self.stickSidebar();
      $window.on('resize.stickySidebar', resizeHandler);
    };

    var smallViewports = function() {
      self.unstickSidebar();
      $window.off('resize.stickySidebar');
    };

    new Constants().MATCHMEDIA_HANDLER('SMALL', largeViewports, smallViewports);
  }
};

module.exports = StickySidebar;
