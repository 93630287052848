'use strict';

var ResponsiveMap = require('./lib/responsive-map');
var HoverHandler = require('./lib/hover-handler');

// Constructor
var ImageMap = function () {
  var self = this;

  self.config = {
    container: '.image-map__map-container',
    overlay: '.image-map__overlay',
    legendLink: '.image-map__legend-link',
  };

  self.init = function () {
    new ResponsiveMap({
      container: self.config.container,
    }).init();

    new HoverHandler({
      container: self.config.container,
      overlay: self.config.overlay,
      link: self.config.legendLink,
    }).init();
  };
};

module.exports = ImageMap;
