"use strict";

var Constants = require("../../constants/constants");
var MobileVisibility = require("./mobile-visibility");

var MyUdg = function(config) {
  var self = this;
  var $html = $("html");
  var $modal = config.myUdg;
  var $inner = config.myUdgInner;
  var $button = config.toggleMyUdg;
  var $desktopButton = config.toggleMyUdgIsDesktop;
  var myUdgOpenClass = config.myUdgOpenClass;
  var navOpenClass = config.navOpenClass;
  var searchModalOpenClass = config.searchModalOpenClass;

  self.clickHandler = function() {
    $button.on(
      "click",
      new Constants().THROTTLE(function(e) {
        e.preventDefault();

        if (
          $modal.hasClass("is-visible") &&
          !$modal.hasClass("velocity-animating")
        ) {
          self.hideModal();
        } else if (!$modal.hasClass("velocity-animating")) {
          self.showModal();
        }
      }, 300)
    );
  };

  self.showModal = function() {
    var delay = 0;

    // Hide main nav if open
    if ($html.hasClass(navOpenClass)) {
      new MobileVisibility(config).hideNav();
      delay = 460;
    }

    if ($html.hasClass(searchModalOpenClass)) {
      delay = 300;
    }

    $modal.velocity("slideDown", {
      duration: 260,
      delay: delay,
      complete: function() {
        $inner.velocity(
          {
            opacity: 1
          },
          {
            duration: 160
          }
        );
        $modal.addClass("is-visible");
        $html.addClass(myUdgOpenClass);
      }
    });
  };

  self.hideModal = function() {
    $inner.velocity(
      {
        opacity: 0
      },
      {
        duration: 150
      }
    );
    $modal.velocity("slideUp", {
      duration: 200,
      complete: function() {
        $modal.removeClass("is-visible");
        $html.removeClass(myUdgOpenClass);
      }
    });
  };

  self.clickOutsideHandler = function() {
    $(document).on("click", function(e) {
      if (!$(e.target).closest($modal).length) {
        if ($modal.hasClass("is-visible")) {
          self.hideModal();
        }
      }
    });
  };

  self.modalPosition = function() {
    if ($desktopButton.length > 0 && $modal.length > 0) {
      var offset =
        $desktopButton.offset().left +
        $desktopButton.width() / 2 -
        $modal.width() / 2;
      $modal.css("left", offset);
    }
  };

  self.reset = function() {
    $modal.removeAttr("style");
    $modal.removeClass("is-visible");
    $html.removeClass(myUdgOpenClass);
    $(window).off("resize.myUdg");
  };

  self.init = function() {
    var largeViewports = function() {
      self.modalPosition();
      $(window).on(
        "resize.myUdg",
        new Constants().THROTTLE(self.modalPosition, 100)
      );
    };

    var smallViewports = function() {
      self.reset();
    };

    new Constants().MATCHMEDIA_HANDLER(
      "MEDIUM",
      largeViewports,
      smallViewports
    );

    self.clickHandler();
    self.clickOutsideHandler();
  };
};

module.exports = MyUdg;
