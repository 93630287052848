'use strict'

import Pikaday from 'pikaday'
import {
  i18n
} from './i18n'
import Velocity from 'velocity-animate'

export default class SidebarAdvancedSearch {
  initCalendars() {
    if (!document.querySelector('.sidebar-advanced-search__date-input')) return false

    const currLang = document.querySelector('html').getAttribute('lang')
    const inputs = document.querySelectorAll('.sidebar-advanced-search__date-input')
    Array.prototype.forEach.call(inputs, (field) => {
      new Pikaday({
        field,
        format: 'DD/MM/YYYY',
        showDaysInNextAndPreviousMonths: true,
        ...i18n[currLang]
      })
    })
  }

  containerOnCollapse() {
    const show = document.querySelector('.sidebar-advanced-search__show-extra-fields')

    if (!show) return false

    const searchButton = document.querySelector('.sidebar-advanced-search__default-input-button')

    show.addEventListener('click', () => {
      this.showHeader()
      this.showContainer()
      this.hideSidebarEls()
      searchButton.classList.add('is-disabled')
      show.classList.add('is-hidden')
    })

    const hide = document.querySelector('.sidebar-advanced-search__hide-extra-fields')

    hide.addEventListener('click', () => {
      this.hideHeader()
      this.hideContainer()
      this.showSidebarEls()
      searchButton.classList.remove('is-disabled')
    })
  }

  showHeader() {
    const header = document.querySelector('.sidebar-advanced-search__header')
    const headerInner = document.querySelector('.sidebar-advanced-search__header-inner')

    Velocity(header, 'slideDown', {
      duration: 400,
      easing: 'easeOutQuart',
      complete: function () {
        Velocity(headerInner, {
          opacity: 1
        })
      }
    })
  }

  hideHeader() {
    const header = document.querySelector('.sidebar-advanced-search__header')
    const headerInner = document.querySelector('.sidebar-advanced-search__header-inner')

    Velocity(headerInner, {
      opacity: 0
    }, {
      delay: 400,
      complete: function () {
        Velocity(header, 'slideUp', {
          duration: 400,
          easing: 'easeOutQuart'
        })
      }
    })
  }

  showContainer() {
    const container = document.querySelector('.sidebar-advanced-search__extra-fields')
    const innerContainer = document.querySelector('.sidebar-advanced-search__extra-fields-inner')


    Velocity(container, 'slideDown', {
      duration: 400,
      easing: 'easeOutQuart',
      delay: 400,
      complete: function () {
        Velocity(innerContainer, {
          opacity: 1
        })
      }
    })
  }

  hideContainer() {
    const container = document.querySelector('.sidebar-advanced-search__extra-fields')
    const innerContainer = document.querySelector('.sidebar-advanced-search__extra-fields-inner')
    const show = document.querySelector('.sidebar-advanced-search__show-extra-fields')

    Velocity(innerContainer, {
      opacity: 0
    }, {
      complete: function () {
        Velocity(container, 'slideUp', {
          duration: 400,
          easing: 'easeOutQuart',
          complete: function () {
            show.classList.remove('is-hidden')
          }
        })
      }
    })
  }

  hideSidebarEls() {
    const els = document.querySelectorAll('.hide-on-active-advanced-search')

    if (!els) return false

    Array.prototype.forEach.call(els, (el) => {
      Velocity(el, 'fadeOut')
    })
  }

  showSidebarEls() {
    const els = document.querySelectorAll('.hide-on-active-advanced-search')

    if (!els) return false

    Array.prototype.forEach.call(els, (el) => {
      Velocity(el, 'fadeIn')
    })
  }

  init() {
    this.initCalendars()
    this.containerOnCollapse()
  }
}
