"use strict";

var Constants = require("../../constants/constants");
var MobileVisibility = require("./mobile-visibility");

var SearchModal = function(config) {
  var self = this;
  var $html = $("html");
  var $button = config.toggleSearchModal;
  var $modal = config.searchModal;
  var $inner = config.searchModalInner;
  var $input = config.searchModalInput;
  var navOpenClass = config.navOpenClass;
  var searchModalOpenClass = config.searchModalOpenClass;
  var myUdgOpenClass = config.myUdgOpenClass;

  self.clickHandler = function() {
    $button.on(
      "click",
      new Constants().THROTTLE(function() {
        var $this = $(this);
        new Constants().ARIA_EXPANDED_TOGGLE($this);
        self.modalVisibility();
      }, 300)
    );

    document
      .querySelector(".main-header__search-modal-close")
      .addEventListener("click", () => {
        self.hideModal();
      });
  };

  self.modalVisibility = function() {
    if (
      $modal.hasClass("is-visible") &&
      !$modal.hasClass("velocity-animating")
    ) {
      self.hideModal();
    } else if (!$modal.hasClass("velocity-animating")) {
      self.showModal();
    }
  };

  self.showModal = function() {
    var delay = 0;

    // Hide main nav if open
    if ($html.hasClass(navOpenClass)) {
      new MobileVisibility(config).hideNav();
      delay = 460;
    }

    if ($html.hasClass(myUdgOpenClass)) {
      delay = 300;
    }

    $modal.velocity("slideDown", {
      display: "block",
      delay: delay,
      complete: function() {
        $modal.addClass("is-visible");
        $html.addClass(searchModalOpenClass);

        $inner.velocity(
          {
            opacity: "1"
          },
          {
            duration: "200ms",
            complete: function() {
              $input.focus();
            }
          }
        );
      }
    });
  };

  self.hideModal = function() {
    $inner.velocity(
      {
        opacity: "0"
      },
      {
        duration: "200ms"
      }
    );
    $modal.velocity("slideUp", {
      display: "none",
      complete: function() {
        $modal.removeClass("is-visible");
        $html.removeClass(searchModalOpenClass);
      }
    });
  };

  self.clickOutsideHandler = function() {
    $(document).on("click", function(e) {
      if (!$(e.target).closest($modal).length) {
        if ($modal.hasClass("is-visible")) {
          self.hideModal();
        }
      }
    });
  };

  self.reset = function() {
    $html.removeClass(searchModalOpenClass);
  };

  self.init = function() {
    self.clickHandler();
    self.clickOutsideHandler();
  };
};

module.exports = SearchModal;
