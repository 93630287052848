'use strict';

/**
 * Tabs component details:
 *
 * Uses two different strategies:
 * - Small Screens: Simple Link System
 * - Large Screens: Tab System
 *
 * On init, the first link and the first tab have
 * the class 'is-active', hence they are visible
 * using CSS display block. Others are not displayed.
 *
 * Enable and disable
 * ================================================
 * When changing from small to large devices,
 * we check what tab has the 'is-active' class and show it,
 * hiding the tabs without 'is-active' via CSS.
 * When changing from large to small devices,
 * all tabs receive the 'display:block' attribute.
 *
 * jQuery 'this' strategy
 * ================================================
 * To avoid creating too many jQuery objects, the $(this)
 * object is often associated to a simple variable called '$this':
 * - var $this = $(this);
 */

var Constants = require('../constants/constants');
var StickySidebar = require('../sticky-sidebar/sticky-sidebar');

var Tabs = function() {

  var self = this;

  self.config = {
    'tabs'                :  $('.tabs'),
    'links'               :  $('.tabs__nav-link'),
    'panels'              :  $('.tabs__panel'),
  }

  var $tabs = self.config.tabs;
  var $links = self.config.links;
  var $panels = self.config.panels;
  let tabsActive;


  // Parse Links and Tabpanels
  // ================================================
  self.parseTabsEls = function() {

    $tabs.each(function() {
      var $this = $(this);
      var $currLink = $this.find($links);
      var $currPanel = $this.find($panels);

      self.clickNavHandler($currLink, $currPanel);
      self.keyboardNavHandler($currLink, $currPanel);
    });
  }


  // Setup tab system based on the screen size
  // ================================================
  self.setupTabSystem = function() {

    // Enable the Tab System
    var enableTabSystem = function() {
      tabsActive = true;

      // Link settings
      $links.each( function(index) {
        var $this = $(this);

        $this.attr('role', 'tab');

        if (index === 0) {
          $this.addClass('is-active');
          $this.attr('tabindex', '0');
          $this.attr('aria-selected', 'true');
        } else {
          $this.attr('tabindex', '-1');
          $this.attr('aria-selected', 'false');
        }

        // CLICK PREVENT DEFAULT
      });

      // Panel settings
      $panels.each( function(index) {
        var $this = $(this);

        $this.attr('role', 'tabpanel');

        if (index === 0) {
          $this.css('display', 'block');
          $this.addClass('is-active');
          $this.attr('tabindex', '0');
        } else {
          $this.css('display', 'none');
          $this.attr('tabindex', '-1');
        }
      });
    };

    // Disable the Tab System
    var disableTabSystem = function() {
      tabsActive = false;

      // Link settings
      $links.each( function() {
        var $this = $(this);

        $this.removeClass('is-active');
        $this.removeAttr('role');
        $this.attr('tabindex', '0');
        $this.removeAttr('aria-selected');
      });

      // Panel settings
      $panels.each( function() {
        var $this = $(this);

        $this.css('display', 'block');
        $this.removeAttr('role');
        $this.removeAttr('tabindex');
      });

    };

    new Constants().MATCHMEDIA_HANDLER('LARGE', enableTabSystem, disableTabSystem);
  }


  // Handle Click Navigation
  // ================================================
  self.clickNavHandler = function($links, $panels) {

    $links.on('click', function(e) {

      if (tabsActive) {
        e.preventDefault();
        var $this = $(this);

        if (!$this.hasClass('is-active')) {
          var $panelToShow = $($this.attr('href'));
          var showCb = null;

          self.hidePanel($panels, $links);
          self.showPanel($panelToShow, $this, showCb);
        }

        setTimeout(function () {
          new StickySidebar().forceRecalc();
        }, 10);
      }
    });
  }


  // Handle Keyboard Navigation
  // ================================================
  self.keyboardNavHandler = function($links, $panels, $tabs) {

    $links.on('keydown', function(e) {

      if (tabsActive) {
        var $this = $(this);
        var activeTabIndex = $links.index($this);
        var tabToShowIndex;

        // if Left Arrow (37)
        if ((e.keyCode || e.which) === 37) {

          if (activeTabIndex === 0 ) {
            tabToShowIndex = $links.length - 1;
          } else {
            tabToShowIndex = activeTabIndex - 1;
          }

          self.keyboardNav(activeTabIndex, tabToShowIndex, $links, $panels);

        // if Right Arrow (39)
        } else if ((e.keyCode || e.which) === 39) {

          if (activeTabIndex === ($links.length - 1)) {
            tabToShowIndex = 0;
          } else {
            tabToShowIndex = activeTabIndex + 1;
          }

          self.keyboardNav(activeTabIndex, tabToShowIndex, $links, $panels);
        }
      }
    });
  }


  // Show and Hide panels
  // ================================================

  self.hidePanel = function($panels, $links) {

    // Tab Deactivated (all if global $links is passed)
    $links.removeClass('is-active');
    $links.attr('tabindex', '-1');
    $links.attr('aria-selected', 'false');

    // Panel Hidden (all if global $panels is passed)
    $panels.removeClass('is-active');
    $panels.css('display', 'none'); // Due to velocity animation using 'display'
    $panels.attr('tabindex', '-1');
    $panels.attr('aria-selected', 'false');
  }

  self.showPanel = function($panelShown, $tabActivated, cb) {

    // Tab Activated
    $tabActivated.addClass('is-active');
    $tabActivated.attr('tabindex', '0');
    $tabActivated.attr('aria-selected', 'true');

    // Panel Shown
    $panelShown.addClass('is-active');
    $panelShown.attr('tabindex', '0');
    $panelShown.attr('aria-selected', 'true');
    $panelShown.velocity(
      'fadeIn',
      {
        complete: function() {

          if (typeof cb === 'function') {
            cb();
          }
        }
      }
    )
  }


  // Keyboard Navigation
  // ================================================
  self.keyboardNav = function(activeTabIndex, tabToShowIndex, $links, $panels) {
    // eq(): Reduce the set of matched elements
    // to the one at the specified index.
    var $activePanel = $panels.eq(activeTabIndex);
    var $panelToShow = $panels.eq(tabToShowIndex);
    var $linkToShow = $links.eq(tabToShowIndex);
    var showCb = null;

    self.hidePanel($activePanel, $links);
    self.showPanel($panelToShow, $linkToShow, showCb);
    $linkToShow.focus();

    setTimeout(function () {
      new StickySidebar().forceRecalc();
    }, 10);
  }


  // Init
  // ================================================
  self.init = function() {
    self.parseTabsEls();
    self.setupTabSystem();
  }
};

module.exports = Tabs;
