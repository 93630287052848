'use strict';

var Constants = require('../constants/constants');

var InlineNav = function() {

  var self = this;

  self.config = {
    'nav'     :  $('.inline-nav'),
    'button'  :  $('.inline-nav__collapse-nav'),
    'list'    :  $('.inline-nav__list')
  }

  var $nav = self.config.nav;
  var $button = self.config.button;
  var $list = self.config.list;


  self.parseNav = function() {

    $nav.each(function() {
      var $this = $(this);
      var $currButton = $this.find($button);
      var $currList = $this.find($list);

      self.clickHandler($currButton, $currList);
    });
  }


  self.clickHandler = function($button, $list) {

    $button.on('click', function() {
      self.listVisibility($button, $list);
    });
  }


  self.listVisibility = function($button, $list) {

    if ($list.hasClass('is-visible') && !$list.hasClass('velocity-animating')) {
      self.hideList($button, $list);
    } else if (!$list.hasClass('velocity-animating')) {
      self.showList($button, $list);
    }
  }


  self.hideList = function($button, $list) {

    $button.removeClass('is-active');
    $list.removeClass('is-visible');
    $list
      .velocity(
        {
          opacity: '0'
        }
      )
      .velocity(
        'slideUp',
        {
          easing: 'easeOutQuart'
        }
      );
  }


  self.showList = function($button, $list) {

    $button.removeClass('is-active');
    $list.addClass('is-visible');
    $list
      .velocity(
        'slideDown',
        {
          easing: 'easeOutQuart'
        }
      )
      .velocity(
        {
          opacity: '1'
        }
      );
  }


  self.disableCollapse = function() {

    $button.removeClass('is-active');
    $list.removeClass('is-visible');
    $list.removeAttr('style');
    $button.off('click');
  }


  self.init = function($button, $list) {

    var matchMq = function() {
      self.disableCollapse();
    };

    var unmatchMq = function() {
      self.parseNav();
    };

    new Constants().MATCHMEDIA_HANDLER('MEDIUM', matchMq, unmatchMq);
  }
};

module.exports = InlineNav;
