'use strict';


// Constructor
var ShareModule = function () {

  var self = this;

  var websiteData = {
    url: encodeURIComponent(window.location.href),
    title: encodeURIComponent(document.title),
  }

  var sharingURLs = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${websiteData.url}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${websiteData.url}&title=${websiteData.title}`,
    pinterest: `https://pinterest.com/pin/create/button/?url=${websiteData.url}&description=${websiteData.title}`,
    twitter: `https://twitter.com/intent/tweet?url=${websiteData.url}&text=${websiteData.title}`,
    whatsapp:`https://wa.me/?text=${websiteData.title}%0D%0A${websiteData.url}`,
  }


  // Attach URLs to each button
  self.attachURLs = function () {

    Object.keys(sharingURLs).forEach(function (key) {

      var buttons = document.querySelectorAll('#shareModal .share-module--' + key);
      var buttonUrl = sharingURLs[key];

      for (var i=0; i < buttons.length; i++) {
        buttons[i].setAttribute('href', buttonUrl);
      }

    });
  }


  self.init = function () {
    self.attachURLs();
  }

};


module.exports = ShareModule;
