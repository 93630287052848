"use strict";

var Collapse = require("./collapse");

var MobileParseItems = function($item, $button, $subnav) {
  var self = this;

  self.parseItems = function() {
    $item.each(function() {
      var $this = $(this);
      var $link = $this.children("a");
      var $btn = $this.find($button).add($link);
      var $sbnv = $this.find($subnav);
      var openByDefault = $this.hasClass("is-open-by-default");

      if ($sbnv.length > 0) {
        new Collapse().init($btn, $sbnv, null, false, openByDefault);
      }
    });
  };

  self.init = function() {
    self.parseItems();
  };
};

module.exports = MobileParseItems;
