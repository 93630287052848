'use strict';

var Constants = require('../../constants/constants');
var hoverintent = require('hoverintent');
var MyUdg = require('./my-udg');

var DesktopSubnavs = function(config) {
  var self = this;
  var $html = $('html');
  var $body = $('body');
  var scrollbarWidth = new Constants().SCROLLBAR_WIDTH();
  var $item = config.mainNavItem;
  var $link = config.mainNavLink;
  var $subnav = config.subnav;
  var $inner = config.subnavContainer;
  var closeButtonTemplate = config.closeSubnavBtnTemplate;
  var $subnavList = config.subnavList;
  var $subnavLink = config.subnavLink;
  var $outer = config.mainNav;
  var $mainHeader = config.mainHeader;
  var myUdgOpenClass = config.myUdgOpenClass;


  self.parseItems = function() {
    $item.each(function() {
      var $this = $(this),
        $lnk = $this.find($link),
        $sbnv = $this.find($subnav),
        $innr = $this.find($inner);

      if (!Modernizr.touchevents) {
        self.hoverHandler($lnk, $sbnv, $innr);
      }

      self.clickHandler($lnk, $sbnv, $innr);
      self.keydownHandler($lnk, $sbnv, $innr);

      self.appendCloseButton($lnk, $sbnv, $innr);
    });
  };

  self.appendCloseButton = function($myLink, $mySubnav, $myInner) {
    var $closeButton = $(closeButtonTemplate);
    $myInner.append($closeButton);
    self.closeClickHandler($myLink, $mySubnav, $closeButton);
  };

  self.closeClickHandler = function($myLink, $mySubnav, $closeButton) {
    $closeButton.on('click', function() {
      self.hideCurrentSubnavs(null, null, true);
      $myLink.removeClass('is-active');
      $myLink.focus();
    });
  };

  self.hoverHandler = function($myLink, $mySubnav, $myInner) {
    // var myLink = $myLink[0],
    //   mq = new Constants().MEDIA_QUERIES.MEDIUM,
    //   mql = window.matchMedia(mq);

    // var hoverEvents = hoverintent(
    //   myLink,
    //   function(e) {
    //     self.subnavVisibility($myLink, $mySubnav, $myInner);
    //   },
    //   function() {}
    // ).options({ interval: 100 });

    // // Remove hoverintent listeners
    // var mediaQueryHandler = function(mql) {
    //   if (!mql.matches) {
    //     hoverEvents.remove();
    //   }
    // };

    // mql.addListener(mediaQueryHandler);
    // mediaQueryHandler(mql);
  };

  self.keydownHandler = function($myLink, $mySubnav, $myInner) {

    // On keydown Enter, activate or deactivate the subnav
    $myLink.on('keydown', function(e) {

      if (event.key == 'Enter') {
        e.preventDefault();

        if ($myLink.hasClass('is-active')) {
          self.hideCurrentSubnavs(null, null, true);
          $myLink.removeClass('is-active');
        } else {
          self.subnavVisibility($myLink, $mySubnav, $myInner);
        }
      }
    });

    // $myLink.on('click', function(e) {
    //   e.preventDefault();
    // });
  };


  // Click on a Main Menu link
  self.clickHandler = function($myLink, $mySubnav, $myInner) {

    $myLink.on('click', function(e) {
      e.preventDefault();

      if ($myLink.hasClass('is-active')) {
        self.hideCurrentSubnavs(null, null, true);
        $myLink.removeClass('is-active');
      } else {
        self.subnavVisibility($myLink, $mySubnav, $myInner);
      }
    });
  };


  self.subnavVisibility = function($myLink, $mySubnav, $myInner) {
    var showMySubnav = function() {
      self.showSubnav($mySubnav, $myInner);
    };

    if ($subnav.hasClass('is-visible')) {
      self.hideCurrentSubnavs($mySubnav, showMySubnav);
    } else {
      showMySubnav();
    }

    $link.removeClass('is-active');
    $myLink.addClass('is-active');
    // $subnavLink.removeClass('is-dim'); // Reset opacity effect for subnav links
  };


  self.hideCurrentSubnavs = function($currentSubnav, cb, lastTime) {
    var lastTime = lastTime || false;

    $subnav.not($currentSubnav).each(function() {
      var $this = $(this);

      if ($this.hasClass('is-visible')) {
        var $innr = $this.find($inner);

        self.hideSubnav($this, $innr, lastTime, cb);
      }
    });

    $link.removeClass('is-dim'); // Reset opacity effect for subnav links
  };


  // Mouse moves outside of the menu
  // self.mouseOutOuter = function() {
  //   hoverintent(
  //     $outer[0],
  //     function() {},
  //     function() {
  //       self.hideCurrentSubnavs(null, null, true);
  //       $link.removeClass('is-active');
  //     }
  //   );
  // };


  // Mouse Clicks outside of the menu
  self.mouseClickOutside = function() {

    document.addEventListener('click', (e) => {
      if (!$outer[0].contains(e.target)) {
        self.hideCurrentSubnavs(null, null, true);
        $link.removeClass('is-active');
      }
    });
  };


  // Mouse Moves outside the menu
  self.focusOutOuter = function() {
    var $lastLink = $outer.find('a').last();

    $lastLink.blur(function() {
      self.hideCurrentSubnavs(null, null, true);
    });
  };


  self.showSubnav = function($subnav, $inner) {
    if (!$inner.hasClass('velocity-animating')) {
      $subnav.addClass('is-visible');
      $subnav.css('z-index', '2');
      $inner
        .velocity('slideDown', {
          duration: '200ms'
        })
        .velocity(
          {
            opacity: '1'
          },
          {
            duration: '100ms',
            complete: function() {
              $body.addClass('no-scroll');
              $body.css('padding-right', scrollbarWidth);
              $mainHeader.css('right', scrollbarWidth);
            }
          }
        );

      if ($html.hasClass(myUdgOpenClass)) {
        new MyUdg(config).hideModal();
      }
    }
  };


  self.hideSubnav = function($subnav, $inner, lastTime, cb) {
    var delay = lastTime === true ? '0' : '300ms';

    $subnav.removeClass('is-visible');
    $subnavLink.removeClass('is-dim'); // Reset opacity effect for subnav links
    $subnav.css('z-index', '1');
    $inner
      .velocity(
        {
          opacity: '0'
        },
        {
          duration: '100ms',
          complete: function() {
            if (typeof cb === 'function') {
              cb();
            }
            $body.removeClass('no-scroll');
            $body.css('padding-right', '0');
            $mainHeader.css('right', '0');
          }
        }
      )
      .velocity('slideUp', {
        delay: delay,
        duration: '250ms'
      });
  };


  self.reset = function() {
    var $lastLink = $outer.find('a').last();

    $link.off('focus');
    $link.removeClass('is-active');
    $subnav.removeClass('is-visible');
    $subnav.removeAttr('style');
    $subnavList.removeClass('is-visible');
    $subnavList.removeAttr('style');
    $inner.velocity('stop');
    $inner.removeAttr('style');
    $lastLink.off('blur');
  };


  self.init = function() {
    self.parseItems();
    // self.mouseOutOuter();
    self.mouseClickOutside();
    self.focusOutOuter();
  };
};

module.exports = DesktopSubnavs;
