"use strict";

var Constants = require("../../constants/constants");
var MyUdg = require("./my-udg");

var DesktopSticky = function(config) {
  var self = this;
  var $html = $("html");
  var $header = config.mainHeader;
  var myUdgOpenClass = config.myUdgOpenClass;
  var isSubhomeClass = config.isSubhomeClass;

  self.scrollListener = function() {
    $(window).on("scroll", self.foldHandler);
  };

  self.foldHandler = new Constants().THROTTLE(function() {
    var st = $(window).scrollTop();

    if (st > 250) {
      self.fold();
    } else {
      self.unfold();
    }
  }, 200);

  self.fold = function() {
    if (
      !$header.hasClass("is-folded") &&
      !$header.hasClass("velocity-animating")
    ) {
      $header.velocity("stop");
      $header.velocity(
        {
          translateY: "-100%",
          opacity: "0"
        },
        {
          duration: "160ms",
          complete: function() {
            $header.addClass("is-folded");
            $header.velocity(
              {
                translateY: "0%",
                opacity: "1"
              },
              {
                duration: "160ms"
              }
            );
          }
        }
      );

      if ($html.hasClass(myUdgOpenClass)) {
        new MyUdg(config).hideModal();
      }
    }
  };

  self.unfold = function() {
    if (
      $header.hasClass("is-folded") &&
      !$header.hasClass("velocity-animating")
    ) {
      $header.velocity("stop");
      $header.velocity(
        {
          translateY: "-100%",
          opacity: "0"
        },
        {
          duration: "160ms",
          complete: function() {
            $header.removeClass("is-folded");
            $header.velocity(
              {
                translateY: "0%",
                opacity: "1"
              },
              {
                duration: "160ms"
              }
            );
          }
        }
      );

      if ($html.hasClass(isSubhomeClass)) {
        new DesktopSubhomeName(config).showName(true);
      }
    }
  };

  self.reset = function() {
    $(window).off("scroll");
    $header.removeClass("is-folded");
    $header.removeAttr("style");
  };

  self.init = function() {
    self.scrollListener();
  };
};

module.exports = DesktopSticky;
