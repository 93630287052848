'use strict';

var Constants = require('../constants/constants');
var MobileVisibility = require('./lib/mobile-visibility');
var MobileParseItems = require('./lib/mobile-parse-items');
var Collapse = require('./lib/collapse');
var SearchModal = require('./lib/search-modal');
var DesktopSubnavs = require('./lib/desktop-subnavs');
var DesktopSticky = require('./lib/desktop-sticky');
var DesktopInnerSubnavs = require('./lib/desktop-inner-subnavs');
var MyUdg = require('./lib/my-udg');
var Highlight = require('./lib/highlight');

// Constructor
var MainHeader = function() {
  var self = this;
  var $html = $('html');

  self.config = {
    mainHeader: $('.main-header'),
    mainHeaderHeight: $('.main-header').css('height'),
    toggleMainHeaderInnerButton: $('.main-header__toggle-nav'),
    mainHeaderInner: $('.main-header__inner'),
    navOpenClass: 'main-nav-is-open',

    // Publics nav
    togglePublicsNav: $('.main-header__publics-nav-toggle'),
    publicsNav: $('.main-header__publics-nav'),

    // Main nav
    mainNav: $('.main-header__main-nav'),
    mainNavItem: $('.main-header__main-nav-list > li'),
    mainNavLink: $('.main-header__main-nav-list > li > a'),

    // Subnav
    subnav: $('.main-header__subnav'),
    subnavList: $('.main-header__subnav-list'),
    subnavContainer: $('.main-header__subnav-container'),
    subnavItem: $('.main-header__subnav-list > li'),
    subnavLink: $('.main-header__subnav-list > li > a'),
    subnavIconClass: 'main-header__subnav-list-icon',
    subnavIconTemplate:
      '<span class="icon icon--subnav-arrow main-header__subnav-list-icon"></span>',
    closeSubnavBtnClass: 'main-header__close-subnav',
    closeSubnavBtnTemplate:
      '<button type="button" aria-label="Tancar Menu" class="main-header__close-subnav"><span class="icon icon--close-inner-subnav"></span></button>',

    // Inner subnav
    innerSubnavContainer: $('.main-header__subnav-inner-container'),
    innerSubnav: $('.main-header__subnav-inner-list'),
    innerSubnavLink: $('.main-header__subnav-inner-list > li > a'),
    backInnerSubnavBtnClass: 'main-header__back-inner-subnav',
    backInnerSubnavBtnTemplate:
      '<button type="button" aria-label="Tancar Submenu" class="main-header__back-inner-subnav"><span class="icon icon--back-inner-subnav"></span></button>',

    // Toggle subnavs
    toggleSubnav: $('.main-header__toggle-subnav'),
    toggleInnerSubnav: $('.main-header__toggle-inner-subnav'),

    // Search modal
    searchModal: $('.main-header__search-modal'),
    searchModalInner: $('.main-header__search-modal-inner'),
    searchModalInput: $('.main-header__search-input'),
    toggleSearchModal: $('.main-header__search-toggle'),
    searchModalOpenClass: 'search-modal-is-open',

    // My UdG
    myUdg: $('.main-header__my-udg'),
    myUdgInner: $('.main-header__my-udg-inner'),
    toggleMyUdg: $('.main-header__my-udg-toggle'),
    toggleMyUdgIsDesktop: $('.main-header__my-udg-toggle.is-desktop'),
    myUdgOpenClass: 'my-udg-is-open',

    // Highlight
    highlightContainer: $('.main-header__subnav-highlight-container'),
    highlightData: $('.main-header__subnav-highlight-data'),
    highlightTemplate: function(data) {
      var template = '<div class="main-header__highlight">';

      // Open <a> tag
      template += '<a href="' + data.href + '">';

      template += '<div class="main-header__highlight-image">';

      template +=
        '<span class="main-header__highlight-readmore">' +
        data.readmore +
        '</span>';

      template += '<img src="' + data.image + '">';

      // Close image container
      template += '</div>';

      template +=
        '<h3 class="main-header__highlight-title">' + data.title + '</h3>';

      template +=
        '<p class="main-header__highlight-datetime">' + data.datetime + '</p>';

      // Close tags
      template += '</a></div>';

      return template;
    },

    mobileInitClass: 'mobile-main-header-init',
    desktopInitClass: 'desktop-main-header-init',
    isCollapsed: '--collapsed'
  };

  self.init = function() {
    var largeViewports = function() {
      // Resets
      if ($html.hasClass(self.config.mobileInitClass)) {
        new MobileVisibility(self.config).reset();

        new Collapse().reset(
          self.config.togglePublicsNav,
          self.config.publicsNav
        );

        new Collapse().reset(self.config.toggleSubnav, self.config.subnavList);

        new Collapse().reset(self.config.mainNavLink, self.config.subnavList);

        new Collapse().reset(self.config.subnavLink, self.config.subnavList);

        new Collapse().reset(
          self.config.toggleInnerSubnav,
          self.config.innerSubnav
        );

        new Collapse().reset(
          self.config.innerSubnavLink,
          self.config.innerSubnav
        );

        new SearchModal(self.config).reset();

        $html.removeClass(self.config.mobileInitClass);
      }

      $html.addClass(self.config.desktopInitClass);

      new DesktopSubnavs(self.config).init();

      if (!self.config.mainHeader.hasClass(self.config.isCollapsed)) {
        new DesktopSticky(self.config).init();
      }

      new DesktopInnerSubnavs(self.config).init();

      new Highlight(self.config).init();
    };

    var smallViewports = function() {
      // Resets
      if ($html.hasClass(self.config.desktopInitClass)) {
        new DesktopSubnavs(self.config).reset();

        new DesktopInnerSubnavs(self.config).reset();

        new DesktopSticky(self.config).reset();

        $html.removeClass(self.config.desktopInitClass);
      }

      $html.addClass(self.config.mobileInitClass);

      new MobileVisibility(self.config).init();

      new MobileParseItems(
        self.config.mainNavItem,
        self.config.toggleSubnav,
        self.config.subnavList
      ).init();

      new MobileParseItems(
        self.config.subnavItem,
        self.config.toggleInnerSubnav,
        self.config.innerSubnav
      ).init();

      new Collapse().init(self.config.togglePublicsNav, self.config.publicsNav);
    };

    new Constants().MATCHMEDIA_HANDLER(
      'MEDIUM',
      largeViewports,
      smallViewports
    );

    new SearchModal(self.config).init();

    new MyUdg(self.config).init();
  };
};

module.exports = MainHeader;
