'use strict';

// var Constants = require('../constants/constants');
var SidebarCollapse = require('../sidebar-collapse/sidebar-collapse');

// Constructor
var PubSearch = function() {

  var self = this;

  self.config = {
    'button'     :  $('.pub-search__show-content'),
    'content'    :  $('.pub-search__content'),
    'eventName'  :  'pubSearch'
  }

  self.init = function() {
    new SidebarCollapse(self.config).init();
  }
};

module.exports = PubSearch;
