'use strict';

var hoverintent = require('hoverintent');
var Constants = require('../../constants/constants');

var HoverHandler = function (config) {
  var self = this;
  var $container = $(config.container);
  var $area = $container.find('area');
  var $overlay = $(config.overlay);
  var $link = $(config.link);

  self.parseAreas = function () {
    $area.each(function () {
      var $this = $(this);
      var areaIndex = $this.attr('data-map-area-index');
      self.parseOverlays($this, areaIndex);
    });
  };

  self.parseOverlays = function (_$area, areaIndex) {
    $overlay.each(function () {
      var $this = $(this);
      var overlayIndex = $this.attr('data-map-area-index');

      if (overlayIndex === areaIndex) {
        self.hoverEvents(_$area, $this);
        self.parseLinks($this, areaIndex);
      }
    });
  };

  self.parseLinks = function (_$overlay, areaIndex) {
    $link.each(function () {
      var $this = $(this);
      var linkIndex = $this.attr('data-map-area-index');

      if (linkIndex === areaIndex) {
        self.hoverEvents($this, _$overlay);
      }
    });
  };

  self.hoverEvents = function (_$el, _$overlay) {
    var _el = _$el[0];
    var mq = new Constants().MEDIA_QUERIES.MEDIUM;
    var mql = window.matchMedia(mq);
    var hoverListener = hoverintent(
      _el,
      function () {
        self.activeOverlay.on(_$overlay);
      },
      function () {
        self.activeOverlay.off(_$overlay);
      }
    );

    // Remove hoverintent listeners
    var mediaQueryHandler = function (m) {
      if (!m.matches) {
        hoverListener.remove();
      }
    };

    mql.addListener(mediaQueryHandler);
    mediaQueryHandler(mql);
  };

  self.activeOverlay = {
    on: function (_$overlay) {
      _$overlay.addClass('is-active').css('z-index', 2);
    },
    off: function (_$overlay) {
      _$overlay.removeClass('is-active').css('z-index', 0);
    },
  };

  self.isVisible = function ($el) {
    if ($el.hasClass('is-visible')) {
      return true;
    }
  };

  self.isNotAnimating = function ($el) {
    if (!$el.hasClass('velocity-animating')) {
      return true;
    }
  };

  self.destroy = function () {
    $overlay.removeClass('is-active').removeAttr('style');
  };

  self.init = function () {
    var largeViewports = function () {
      self.parseAreas();
    };

    var smallViewports = function () {
      self.destroy();
    };

    new Constants().MATCHMEDIA_HANDLER(
      'MEDIUM',
      largeViewports,
      smallViewports
    );
  };
};

module.exports = HoverHandler;
