'use strict';

var Constants = require('../../constants/constants');

var MobileVisibility = function(config) {
  var self = this;
  var $html = $('html');
  var $body = $('body');
  var $button = config.toggleMainHeaderInnerButton;
  var $header = config.mainHeader;
  var $nav = config.mainHeaderInner;
  var navOpenClass = config.navOpenClass;
  var mainHeaderHeight = config.mainHeaderHeight;

  self.clickHandler = function() {
    $button.on(
      'click',
      new Constants().THROTTLE(function() {
        var $this = $(this);

        self.checkVisibility();

        new Constants().ARIA_EXPANDED_TOGGLE($this);
      }, 300)
    );
  };

  self.checkVisibility = function() {
    if ($html.hasClass(navOpenClass)) {
      self.hideNav();
    } else {
      self.showNav();
    }
  };

  self.showNav = function() {
    $body.addClass('no-scroll');

    if (!$header.hasClass('velocity-animating')) {
      $header.velocity(
        {
          height: '100%'
        },
        {
          easing: 'easeOutQuint',
          duration: '350ms',
          complete: function() {
            $html.addClass(navOpenClass);
            $nav.velocity('fadeIn', {
              duration: '200ms'
            });
          }
        }
      );
    }
  };

  self.hideNav = function() {
    $body.removeClass('no-scroll');

    if (!$header.hasClass('velocity-animating')) {
      $nav.velocity('fadeOut', {
        duration: '160ms',
        complete: function() {
          $header.velocity(
            {
              height: mainHeaderHeight
            },
            {
              easing: 'easeOutQuint',
              duration: '270ms',
              complete: function() {
                $html.removeClass(navOpenClass);
              }
            }
          );
        }
      });
    }
  };

  self.reset = function() {
    $button.off('click');
    $html.removeClass(navOpenClass);
    $body.removeClass('no-scroll');
    $header.removeAttr('style');
    $nav.removeAttr('style');
  };

  self.init = function() {
    self.clickHandler();

    $(window).on(
      'resize',
      new Constants().THROTTLE(function() {
        mainHeaderHeight = $header.css('height');
      }, 150)
    );
  };
};

module.exports = MobileVisibility;
